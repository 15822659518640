import React from "react";
import { graphql } from "gatsby";
import { useIntl, navigate } from "gatsby-plugin-intl";
import SEO from "../components/seo";

const IndexPage = ({ data }) => {
  const intl = useIntl();
  const events = data.allEventJson.nodes;
  const timeNow = new Date();

  const comingEvents = events.filter((event) => {
    const eventDuration = event.duration * 60 * 60 * 1000;
    const eventDate = new Date(event.time);
    return eventDate.getTime() + eventDuration > timeNow.getTime();
  });

  comingEvents.sort((a, b) => {
    const firstTime = new Date(a.time).getTime();
    const secondTime = new Date(b.time).getTime();
    return firstTime - secondTime;
  });

  // Prevent the redirection from breaking after event ends
  if (comingEvents.length === 0) {
    const allEvents = events;
    allEvents.sort((a, b) => {
      const firstTime = new Date(a.time).getTime();
      const secondTime = new Date(b.time).getTime();
      return firstTime - secondTime;
    });
    comingEvents.push(allEvents[allEvents.length - 1]);
  }

  return (
    <>
      <SEO title={intl.formatMessage({ id: "title" })} />
      {navigate(`/event/${comingEvents[0].slug}`)}
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allEventJson {
      nodes {
        time
        id
        slug
        duration
      }
    }
  }
`;
